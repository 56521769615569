import { defineStore } from 'pinia'

import useHttp from '@/core/http.js'

export const useWorkspaceStore = defineStore('workspace', {
  state: () => ({
    isPending: true,
    id: null,
    actual: null,
    navigation: null,
    resource: {},
  }),
  actions: {
    fetchWorkspace(X_TEAM_ID) {
      return useHttp('/v1/private/competitions/current', {
        headersExtend: { 'X-TEAM-ID': X_TEAM_ID },
      })
    },
    fetchWorkspaceNavigation(X_TEAM_ID) {
      return useHttp('/v1/private/resources', {
        headersExtend: { 'X-TEAM-ID': X_TEAM_ID },
      })
    },
    // Асинхронная функция, которая использует Promise.all для выполнения обоих запросов
    async fetchData(X_TEAM_ID, loading = true) {
      this.isPending = loading
      try {
        // Запускаем оба запроса параллельно
        const [workspaceResponse, navigationResponse] = await Promise.all([
          this.fetchWorkspace(X_TEAM_ID),
          this.fetchWorkspaceNavigation(X_TEAM_ID),
        ])

        // Обработка ответа для fetchWorkspace
        const workspaceResource = workspaceResponse.resource
        this.id = workspaceResource.id
        this.resource = workspaceResource

        // Обработка ответа для fetchWorkspaceNavigation
        this.navigation = navigationResponse.resources
        this.actual = navigationResponse.actual.slug
        this.isPending = false
      } catch (e) {
        console.log('error', e)
        this.isPending = false
      }
    },
  },
})
